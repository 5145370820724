/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
import vuetify from '@/vuetify'
import router from "@/router";
import store from "@/store";
import App from '@/views/App.vue';
import { ZiggyVue } from 'ziggy';
import { Ziggy } from '@/ziggy';
import qfPermissionsPlugin from '@/vue-plugins/permissions';
import '@/axios/interceptors';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import '@recogito/annotorious/dist/annotorious.min.css';

Vue.use(ZiggyVue, Ziggy);
Vue.use(qfPermissionsPlugin);
Vue.use(vuetify);

const app = new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
    el: '#app',
});

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
})
