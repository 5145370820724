<template>
    <v-app-bar app clipped-left flat color="white">
        <v-container fluid class="d-flex justify-space-between pa-0 ma-0">

            <v-col cols="1">
                <router-link to="/">
                    <v-img src="/images/canopey.png" alt="Canopey Logo" contain></v-img>
                </router-link>
            </v-col>

            <v-col cols="1" class="text-right">
                <div v-if="$store.state.auth.authenticated">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" @click.stop="active = !active">
                                <v-icon>mdi-account-circle</v-icon>
                                <v-icon >{{ userActive }}</v-icon>
                            </div>
                        </template>

                        <v-list>
                            <v-list-item two-line>
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Logged in as</v-list-item-title>
                                    <v-list-item-subtitle>{{ this.$store.state.auth.user.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="handleLogout">
                                <v-list-item-icon>
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-col>
        </v-container>
    </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';
import authActionTypes from "@/store/modules/auth/action-types";

export default {
    data () {
        return {
            active: true,
        }
    },
    methods: {
        ...mapActions({
            signOut: authActionTypes.LOGOUT.PATH,
        }),
        handleLogout (event) {
            event.preventDefault();
            axios.post(this.route('logout'))
                .then(({ data }) => {
                    this.signOut();
                })
        },
    },
    computed: {
        userActive() {
            return this.active ? "mdi-chevron-down" : "mdi-chevron-up";
        },
    },
}
</script>

<style scoped>
</style>
