<template>
    <div>
        <NavDrawer></NavDrawer>
        <v-main class="ml-11" :style="{background: this.$vuetify.theme.themes.light.background}">
            <v-container fluid>
                <breadcrumbs class="px-7 pt-6 pb-0" />
                <router-view class="px-7"/>
                <div class="px-7">
                    <template v-if="currentRouteName === 'dashboard'" >
                        <h1 class="mt-3">Dashboard</h1>

                        <DashboardCards></DashboardCards>
                    </template>
                </div>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import NavDrawer from '../components/NavDrawer';
import Breadcrumbs from "../components/Breadcrumbs";
import DashboardCards from "../components/DashboardCards";

export default {
    components: {
        Breadcrumbs,
        NavDrawer,
        DashboardCards
    },
    data () {
        return {
            items: [
                { title: 'Vendors', icon: 'mdi-store', link: {name: 'dashboard.vendors.index'}, permission: this.$store.state.auth.permissions.VENDOR_READ_WEB.name },          
                { title: 'Products', icon: 'mdi-shopping', link: {name: 'dashboard.products.index'} },
                { title: 'Product Types', icon: 'mdi-shape', link: {name: 'dashboard.categories.product-types'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'Product Sub-types', icon: 'mdi-shape-outline', link: {name: 'dashboard.categories.product-sub-types'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'Orders', icon: 'mdi-truck', link: {name: 'dashboard.orders.index'} },
                { title: 'Spaces', icon: 'mdi-image-frame', link: {name: 'dashboard.categories.spaces'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'FAQs', icon: 'mdi-frequently-asked-questions', link: {name: 'dashboard.faqs'}, permission: this.$store.state.auth.permissions.FAQ_READ_WEB.name },
                { title: 'Influencers', icon: 'mdi-account-multiple-plus', link: {name: 'dashboard.influencers.index'}, permission: this.$store.state.auth.permissions.INFLUENCER_READ_WEB.name },
                { title: 'Accreditations', icon: 'mdi-license', link: {name: 'dashboard.accreditations.index'}, permission: this.$store.state.auth.permissions.ACCREDITATION_READ_WEB.name },
                // { title: 'Reports', icon: 'mdi-chart-areaspline', link: {name: 'dashboard.reports.index'}, permission: this.$store.state.auth.permissions.REPORTS_READ_WEB.name },
                { title: 'CSV Templates', icon: 'mdi-file-document', link: {name: 'dashboard.CSV.index'}, permission: this.$store.state.auth.permissions.CSVIMPORT_READ_WEB.name },
            ],
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        canViewNavItem (navItem) {
            if(typeof navItem.permission !== 'undefined') {
                return this.can(navItem.permission);
            }
            return true
        },
    },
}
</script>

<style lang="scss">
</style>
