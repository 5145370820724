import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
                primary: '#000000',
                secondary: '#383838',
                accent: '#0DB06E',
                error: '#B71C1C',
                greenr: '#0EB06F',
                background: '#F5F5F5',
            },
            dark: {
                primary: '#FFFFFF',
                secondary: '#FFFFF',
                accent: '#0DB06E',
                error: '#B71C1C',
                background: '#FFFFFF'
            },
        },
    },
});
