<template>
    <v-row class="mt-4 dashboard-card">
        <v-col cols="4" md="2" lg="3"  v-for="item in items" :key="item.title" v-if="canViewNavItem(item)">
            <v-card :to=item.link class="d-flex justify-center flex-column pa-5" hover>
                <v-icon color="secondary" class="dashboard-menu-icon">{{ item.icon }}</v-icon>
                <v-card-text class="d-inline-flex justify-center pb-0">
                    <p class="subtitle-1 text--primary font-weight-bold ma-0 text-center">
                        {{ item.title }}
                    </p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data () {
        return {
            items: [
                { title: 'Vendors', icon: 'mdi-store', link: {name: 'dashboard.vendors.index'}, permission: this.$store.state.auth.permissions.VENDOR_READ_WEB.name },          
                { title: 'Products', icon: 'mdi-shopping', link: {name: 'dashboard.products.index'} },
                { title: 'Product Types', icon: 'mdi-shape', link: {name: 'dashboard.categories.product-types'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'Product Sub-types', icon: 'mdi-shape-outline', link: {name: 'dashboard.categories.product-sub-types'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'Orders', icon: 'mdi-truck', link: {name: 'dashboard.orders.index'} },
                { title: 'Spaces', icon: 'mdi-image-frame', link: {name: 'dashboard.categories.spaces'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'FAQs', icon: 'mdi-frequently-asked-questions', link: {name: 'dashboard.faqs'}, permission: this.$store.state.auth.permissions.FAQ_READ_WEB.name },
                { title: 'Influencers', icon: 'mdi-account-multiple-plus', link: {name: 'dashboard.influencers.index'}, permission: this.$store.state.auth.permissions.INFLUENCER_READ_WEB.name },
                { title: 'Accreditations', icon: 'mdi-license', link: {name: 'dashboard.accreditations.index'}, permission: this.$store.state.auth.permissions.ACCREDITATION_READ_WEB.name },
                { title: 'CSV Templates', icon: 'mdi-file-document', link: {name: 'dashboard.CSV.index'}, permission: this.$store.state.auth.permissions.CSVIMPORT_READ_WEB.name },
            ],
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        canViewNavItem (navItem) {
            if(typeof navItem.permission !== 'undefined') {
                return this.can(navItem.permission);
            }
            return true
        },
    },
}
</script>
