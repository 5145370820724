<template>
    <v-main app class="login-remember">
        <v-container fluid class="d-flex">
            <div v-if="successMessage" style="background: #ffffff; padding: 10px 15px;">
                {{ successMessage }}
            </div>
            <v-form v-else ref="form" v-model="valid" lazy-validation @submit="handleSubmit" class="pa-11">

                <h1 class="font-weight-bold text-h6 mb-6">Reset Password</h1>

                <v-row>
                    <v-col cols="12">
                        <label for="reset-password" class="font-weight-bold text-subtitle-2">New Password</label>
                        <v-text-field
                            v-model="auth.password"
                            :rules="passwordRules"
                            type="password"
                            label="New Password"
                            required
                            single-line
                            hide-details="auto"
                            flat
                            solo
                            dense
                            background-color="background"
                            id="reset-password"
                            class="rounded-0 mt-2"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <label for="reset-confirm-password" class="font-weight-bold text-subtitle-2">Confirm Password</label>
                        <v-text-field
                            v-model="auth.password_confirmation"
                            :rules="passwordRules"
                            type="password"
                            required
                            single-line
                            hide-details="auto"
                            flat
                            solo
                            dense
                            background-color="background"
                            id="reset-confirm-password"
                            class="rounded-0 mt-2"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <div class="text-right">
                    <v-btn type="submit" depressed rounded class="mt-4 font-weight-bold px-7 submit-btn text-capitalize" color="secondary">
                        {{ processing ? "Please wait" : "Save New Password" }}
                    </v-btn>
                </div>
            </v-form>
        </v-container>
    </v-main>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data () {
        return {
            successMessage: null,
            processing: false,
            valid: true,
            auth: {
                token: this.$route.params.token,
                email: this.$route.query.email,
                password: null,
                password_confirmation: null,
            },
            passwordRules: [
                v => !!v || 'E-mail is required',
            ],
        };
    },
    methods: {
        ...mapActions({
            signIn: 'auth/login'
        }),
        validate () {
            this.$refs.form.validate();
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation();
        },
        async handleSubmit(event) {
            event.preventDefault();
            this.processing = true;
            await axios.post(this.route('password.update'), this.auth)
                .then(({ data }) => {
                    this.successMessage = data.message;
                })
                .catch(({ response: { data } }) => {
                    alert(data.message);
                })
                .finally(() => {
                    this.processing = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">
</style>
