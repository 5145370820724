import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import AuthLogin from '@/views/Auth/Login.vue';
import AuthForgotPassword from '@/views/Auth/ForgotPassword.vue';
import AuthResetPassword from '@/views/Auth/ResetPassword.vue';
import store from "@/store";

Vue.use(VueRouter);
const routes = [
    {
        path: '/auth/login',
        name: 'auth.login',
        component: AuthLogin,
        meta: {
            middleware: "guest",
            breadcrumb: 'Login',
        },
    },{
        path: '/auth/forgot-password',
        name: 'auth.forgot-password',
        component: AuthForgotPassword,
        meta: {
            middleware: "guest",
            breadcrumb: 'Forgot Password',
        },
    },{
        path: '/auth/reset-password/:token',
        name: 'auth.reset-password',
        component: AuthResetPassword,
        meta: {
            middleware: "guest",
            breadcrumb: 'Reset Password',
        },
    },{
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: "auth",
            breadcrumb: 'Dashboard',
        },
        children: [
            {
                path: '/dashboard',
                name: 'dashboard.home',
                component: () => import('@/views/Home.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Home',
                },
            },{
                path: '/dashboard/faqs',
                name: 'dashboard.faqs',
                component: () => import('@/views/Dashboard/Faqs/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'FAQs',
                },
            },{
                path: '/dashboard/users',
                name: 'dashboard.users.index',
                component: () => import('@/views/Dashboard/Users/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Users',
                },
            },{
                path: '/dashboard/vendors',
                name: 'dashboard.vendors.index',
                component: () => import('@/views/Dashboard/Vendors/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Vendor List',
                },
            },{
                path: '/dashboard/vendors/:id?',
                name: 'dashboard.vendors.edit',
                component: () => import('@/views/Dashboard/Vendors/Edit.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Vendor Edit',
                },
            },{
                path: '/dashboard/products',
                name: 'dashboard.products.index',
                component: () => import('@/views/Dashboard/Products/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Products',
                },
            },{
                path: '/dashboard/influencers',
                name: 'dashboard.influencers.index',
                component: () => import('@/views/Dashboard/Influencers/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Influencers',
                },
            },{
                path: '/dashboard/accreditations',
                name: 'dashboard.accreditations.index',
                component: () => import('@/views/Dashboard/Accreditations/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Accreditations',
                },
            },{
                path: '/dashboard/spaces',
                name: 'dashboard.categories.spaces',
                component: () => import('@/views/Dashboard/Categories/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Spaces',
                    category_level: 1,
                }
            },{
                path: '/dashboard/product-types',
                name: 'dashboard.categories.product-types',
                component: () => import('@/views/Dashboard/Categories/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Product Types',
                    category_level: 2,
                }
            },{
                path: '/dashboard/product-sub-types',
                name: 'dashboard.categories.product-sub-types',
                component: () => import('@/views/Dashboard/Categories/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Product Sub-types',
                    category_level: 3,
                }
            },{
                path: '/dashboard/CSV',
                name: 'dashboard.CSV.index',
                component: () => import('@/views/Dashboard/CSV/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'CSV Templates',
                }
            },{
                path: '/dashboard/orders',
                name: 'dashboard.orders.index',
                component: () => import('@/views/Dashboard/Orders/Index.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Orders',
                }
            },{
                path: '/dashboard/account',
                name: 'dashboard.account',
                component: () => import('@/views/Dashboard/MyAccount.vue'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Account',
                }
            }
        ],
    },{
        path: '/',
        redirect: {
            name: 'dashboard'
        }
    }
];

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let siteName = process.env.MIX_APP_NAME ?? 'Canopey Dashboard';
    document.title = `${to.meta.breadcrumb} – ${siteName}`
    if(to.meta.middleware === "guest") {
        if(store.state.auth.authenticated) {
            next({name: "dashboard.home" });
        }
        next();
    } else {
        if(store.state.auth.authenticated) {
            next();
        } else {
            next({name: "auth.login" });
        }
    }
})

export default router;
