<template>
    <v-app>
        <NavBar></NavBar>
        <router-view></router-view>
        <v-snackbar
            v-for="alert in this.$store.state.alerts" :key="alert.uuid"
            v-model="alert.visible"
            multi-line
            :color="alert.type"
        >
            {{ alert.text }}
        </v-snackbar>
    </v-app>
</template>


<script>
import NavBar from '../components/NavBar';

export default {
    components: {
        NavBar,
    },
}
</script>

<style scoped lang="scss">

</style>
