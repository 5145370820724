import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState(),
    ],
    modules: {
        auth,
    },
    state: {
        alerts: [],
    },
    mutations: {
        [MUTATION_TYPES.ADD_ALERT](state, value) {
            state.alerts.push(value);
        },
        [MUTATION_TYPES.REMOVE_ALERT](state, alertUuid) {
            state.alerts = state.alerts.filter((alert) => {
                return alert.uuid !== alertUuid;
            });
        },
    },
    getters: {
        state(state) {
            return state;
        },
    },
    actions: {
        [ACTION_TYPES.ADD_ALERT.NAME]({ commit }, alertData) {
            const newAlert = Object.assign({
                uuid: uuidv4(),
                visible: true,
                timeout: 2000,
            }, alertData);
            commit(MUTATION_TYPES.ADD_ALERT, newAlert);
            setTimeout(() => {
                commit(MUTATION_TYPES.REMOVE_ALERT, newAlert.uuid);
            }, newAlert.timeout + 500)
        },
    },
});
