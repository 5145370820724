<template>
    <v-navigation-drawer
        app
        v-model="drawer"
        clipped
        :mini-variant.sync="mini"
        permanent
        v-bind:class="{ active: isActive }"
    >
        <v-list nav class="pa-0">
            <v-list-item dark color="primary" v-for="item in primaryItems" :key="item.title" link white :to=item.link v-if="canViewNavItem(item)">
                <v-list-item-icon>
                    <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-list-item class="px-2 chevron-drawer justify-end">
            <v-btn icon @click.stop="mini = !mini">
                <v-icon large color="white">{{ drawerIcon }}</v-icon>
            </v-btn>
        </v-list-item>

        <template v-slot:append>
            <v-list nav class="pa-7">
                <v-list-item :to="item.link" v-for="(item, itemIndex) in secondaryItems" :key="itemIndex" v-if="item.link !== undefined">
                    <v-list-item-icon class="white">
                        <v-icon color="black">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="account">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
export default {
    data () {
        return {
            drawer: true,
            isActive: false,
            primaryItems: [
                { title: 'Vendors', icon: 'mdi-store', link: {name: 'dashboard.vendors.index'}, permission: this.$store.state.auth.permissions.VENDOR_READ_WEB.name },
                { title: 'Products', icon: 'mdi-shopping', link: {name: 'dashboard.products.index'}, permission: this.$store.state.auth.permissions.PRODUCT_READ_WEB.name},
                { title: 'Orders', icon: 'mdi-truck', link: {name: 'dashboard.orders.index'}, permission: this.$store.state.auth.permissions.ORDER_READ_WEB.name },
                { title: 'Spaces', icon: 'mdi-image-frame', link: {name: 'dashboard.categories.spaces'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'Product Types', icon: 'mdi-shape', link: {name: 'dashboard.categories.product-types'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'Product Sub-types', icon: 'mdi-shape-outline', link: {name: 'dashboard.categories.product-sub-types'}, permission: this.$store.state.auth.permissions.CATEGORY_READ_WEB.name },
                { title: 'FAQs', icon: 'mdi-frequently-asked-questions', link: {name: 'dashboard.faqs'}, permission: this.$store.state.auth.permissions.FAQ_READ_WEB.name },
                { title: 'Influencers', icon: 'mdi-account-multiple-plus', link: {name: 'dashboard.influencers.index'}, permission: this.$store.state.auth.permissions.INFLUENCER_READ_WEB.name },
                { title: 'Accreditations', icon: 'mdi-license', link: {name: 'dashboard.accreditations.index'}, permission: this.$store.state.auth.permissions.ACCREDITATION_READ_WEB.name },
                { title: 'CSV Templates', icon: 'mdi-file-document', link: {name: 'dashboard.CSV.index'}},
            ],
            secondaryItems: [
                {
                    title: 'User Settings',
                    icon: 'mdi-cog',
                    link: {name: 'dashboard.account'}
                },{
                    title: 'Vendor Settings',
                    icon: 'mdi-cogs',
                    link: (() => {
                        if(this.$store.state.auth.user.tenant !== undefined && this.$store.state.auth.user.tenant !== null) {
                            return {name: 'dashboard.vendors.edit', params: {id: this.$store.state.auth.user.tenant.id}};
                        }
                        return undefined;
                    })(),
                },
            ],
            mini: true,
        }
    },
    computed: {
        drawerIcon() {
            return this.mini ? "mdi-chevron-right" : "mdi-chevron-left";
        },
    },
    methods: {
        canViewNavItem (navItem) {
            if(typeof navItem.permission !== 'undefined') {
                return this.can(navItem.permission);
            }
            return true
        },
    },
}
</script>

<style scoped>
    .v-list-item__title:not(.account) {
        color: #FFFFFF;
    }

    .v-list-item--link:before {
        background-color: #FFFFFF;
        border-radius: 0px;
    }
</style>
